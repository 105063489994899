:global(.memo) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @screen md {
        &:hover {
            text-overflow: unset;
            white-space: unset;
        }
    }
}