.faq_btn {
    border: 1px solid var(--color);
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 1;
    letter-spacing: 0px;

    @apply wii-max-w-[192px] 
    wii-bg-white 
    wii-text-base 
    wii-rounded-xl 
    wii-overflow-hidden 
    wii-text-black 
    wii-px-4
    wii-ml-2 wii-mt-2;
    &:hover {
        @apply theme-color wii-text-white;
    }
}
