@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/fontello-codes.css";
@import "./assets/css/fontello-embedded.css";
@import "./assets/css/fontello-ie7-codes.css";
@import "./assets/css/fontello-ie7.css";
@import "./assets/css/fontello.css";
@import "animate.css";
@import "../node_modules/swiper/swiper-bundle.css";

@layer utilities {
  .wii-bgcolor {
    background: var(--color)
  }

  .wii-color {
    color: var(--color)
  }

  .theme-color {
    background: transparent 
                linear-gradient(0deg, var(--theme-color) 0%, 
                var(--color) 100%) 0% 0% no-repeat padding-box !important;
  }

  .theme-background {
    background: linear-gradient(var(--theme-background), var(--theme-background)), linear-gradient(#fff, #fff);
  }

  // 圖片&按鈕訊息專用寬度
  .w-spec {
    width: var(--spec-width);
  }

  .h-pic-msg {
    height: var(--pic-message-height);
  }
}

.login-icon {
  width: 13%;
  padding: 5px;
}

.demarcation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demarcation::before,
.demarcation::after {
  display: inline-block;
  content: "";
  height: 1px;
  background-color: #ccc;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.message {
  @apply wii-bg-white
  wii-text-msg
  wii-mt-1
  wii-inline-block;
}

.message-btn {
  @apply wii-p-2 wii-block wii-text-[#1087e9] wii-rounded-md w-spec message;
}

.reply-btn {
  border: 1px solid var(--color);
  @apply wii-block wii-rounded-3xl wii-p-1 wii-min-w-[13%] message;
}

.message-btn:hover,
.reply-btn:hover {
  @apply wii-text-white theme-color;
}

.message-content {
  width: calc(theme("width.full") - theme("width.8"));
  @apply wii-pl-1.5;
}

[data-active="true"]  {
  visibility: visible;
  opacity: 1;
}

[data-active="false"]  {
  visibility: hidden;
  opacity: 0;
}

