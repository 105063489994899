:global(.hyperlink) {
    @apply hover:wii-text-[#40a9ff]  wii-text-[#0b8de4]  wii-font-bold  wii-cursor-pointer;
}

:global(.chatWebPage) {
    border: 0.25px solid #2d94b6;
    @apply hover:wii-text-[#555] hover:wii-bg-[hsla(0,0%,91%,.459)] 
    wii-bg-[#fffff000] wii-text-[#2d94b6] wii-px-[6px] wii-rounded-2xl;
    &:hover {
        border: 0.25px solid #bbb;
    }
}

:global(.message-text:hover) {
    & + :global(.timestamp) {
        display: block;
    }
}

.user-message {
    @apply wii-flex-row-reverse wii-justify-items-end;
    & > p {
        @apply wii-text-white wii-mr-3 theme-color;
    }
}
