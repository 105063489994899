:global(.ScrollbarsCustom-Wrapper) {
    position: unset !important;
}

:global(.ScrollbarsCustom-Track) {
    height: 100% !important;
    z-index: 10;
    background: var(--theme-background) !important;
    width: 7px !important;
}

:global(.ScrollbarsCustom-Thumb) {
    background-color: var(--theme-background) !important;
    &:hover {
        @apply theme-color #{!important};
    }
}

:global(.ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb) {
    width: 7px !important;
}

:global(.ScrollbarsCustom-TrackY) {
    top: 0px !important;
}

