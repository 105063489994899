.menu-icon {
    font-size: 2rem;
}

.menu {
     @apply wii-absolute wii-bottom-11 wii-pointer-events-auto wii-z-10;
}

.menu-button {
    color: var(--color);
    @apply wii-flex-[0_0_30%] wii-bg-white wii-flex wii-flex-col wii-items-center wii-m-1 wii-p-2;
    &:hover {
        background-color: var(--theme-color);
        @apply wii-text-white;
    }
}

