.chatbot {
  cursor: pointer;
  position: absolute;
  bottom: 1em;
  right: 1em;
  border: none;
  visibility: visible;
  @screen lg {
    @apply wii-w-20 wii-h-20;
  }

  @apply wii-w-16 wii-h-16;
}

.chatbot:hover {
  animation: "rubberBand";
  -webkit-animation: "rubberBand";
  animation-duration: 2s;
}

.chatbot-window {
  @apply md:wii-h-[675px] theme-background wii-p-0 wii-relative wii-overflow-hidden wii-h-full wii-max-h-full;
}
